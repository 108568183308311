<script>
import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  data() {
    return {
      formConfig: {},
      poolId: '',
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },

  activated() {
    this.params.poolId = this.$route.query.poolId;
    this.getConfigList('capital-pool-cash-detail', true, false,
      'CRM20210918000002715');
  },
  watch: {
    'params.poolId': function (val) {
      if (val) {
        console.log(val);
      }
    },
  },
  methods: {
    modalClick({ val, row }) {
      console.log(val.code);
    },
  },
};
</script>
